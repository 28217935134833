.industries-container {
    background: #e5f1ff;
    padding: 48px;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    align-items: center;
    justify-content: center;
}

.industries-card {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #4563aa00;
    padding: 10px;
}

.industries-card:hover {
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);

    background: #fff;
    border: 1px solid #4564aa;
    border-radius: 8px;
}

.industries-card h1 {
    color: 000000;
    font-size: 1rem;
    font-weight: 600;
}

@media only screen and (max-width:768px) {
    .industries-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media only screen and (max-width:600px) {
    .industries-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding: 24px;
    }
}