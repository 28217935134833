@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  user-select: none;
}


@font-face {
    font-family: 'BebasNeue-Regular';
    src: url('./assets/font-family/BebasNeue-Regular.ttf') format('truetype');
    ont-weight: 600; /* Adjust the weight according to your font */
}

@font-face {
    font-family: 'NotoSansTagbanwa-Regular';
    src: url('./assets/font-family/NotoSansTagbanwa-Regular.ttf') format('truetype');
    ont-weight: 600; /* Adjust the weight according to your font */
}

@font-face {
    font-family: 'NotoSansChorasmian-Regular';
    src: url('./assets/font-family/NotoSansChorasmian-Regular.ttf') format('truetype');
    ont-weight: 400; /* Adjust the weight according to your font */
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./assets/font-family/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
  }
  
  @font-face {
    font-family: 'Poppins-Regular';
    src: url('./assets/font-family/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Poppins-Italic';
    src: url('./assets/font-family/Poppins-Italic.ttf') format('truetype');
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Poppins-Medium';
    src: url('./assets/font-family/Poppins-Medium.ttf') format('truetype');
    font-weight: medium;
  }
  
  @font-face {
    font-family: 'Poppins-Black';
    src: url('./assets/font-family/Poppins-Black.ttf') format('truetype');
    font-weight: 900; /* Adjust the weight according to your font */
  }
  
  @font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./assets/font-family/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 700; /* Adjust the weight according to your font */
  }
  @font-face {
    font-family: 'Poppins-ThinItalic';
    src: url('./assets/font-family/Poppins-ThinItalic.ttf') format('truetype');
    font-weight: 600; /* Adjust the weight according to your font */
  }
  @font-face {
    font-family: 'Poppins-Light';
    src: url('./assets/font-family/Poppins-Light.ttf');
  }