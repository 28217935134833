
  .footer-logo {
    width: 40%;
  }
 
  
  .bg-primary {
    background-color: #234190; /* Example primary background color */
   
  }
  
  

  