* {
  font-family: poppins;
 
}

.headerImg {
  background-image:url("../assets/home-hero-banner-img-main.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;

}

.enquireBg {
  background-image: url("../assets/enquire-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 600px) {
  .headerImg {
    background-position: top;

  }
}

.serviceBg {
  background: url("../assets/dot-pattern.png") repeat;
  background-size: cover;
}


.animated-text-container {
  overflow: hidden;
}

.animated-text {
  animation: slideFromLeft 2s ease-out;
  /* Adjust the duration and easing as needed */
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}


.industries {
  display: flex;
  padding: 1.5rem;
  align-items: center;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

@media only screen and (max-width: 600px) {
  .industries {
    width: 95%;
  }
}

.industries:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.industries-logo-wrapper {
  flex-shrink: 0;
}

.industries-logo {
  height: 4rem;
  width: 4rem;
}

.industries-content {
  margin-left: 1.5rem;
  padding-top: 0.25rem;
}

.industries-title {
  color: #1a202c;
  font-size: 1.25rem;
  line-height: 1.25;
}

.industries-message {
  color: #718096;
  font-size: 1rem;
  line-height: 1.5;
}

/* sector css  */
.carousel-container {
  width: 90%; /* Increase the width to utilize more space */
  margin: 0 auto;
  padding: 20px 0; /* Add some padding to the top and bottom */
}

.carousel-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel-item img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 0.5rem; /* rounded-lg */
}

.carousel-item p {
  margin-top: 0.75rem; /* mt-3 */
  text-align: center;
  font-size: 1.25rem; /* text-xl */
  font-weight: 600; /* font-semibold */
}


.carousel-item:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
}
.hover\:bg-blue-500:hover {
  background-color: #4299e1;
}

.hover\:text-white:hover {
  color: #ffffff;
}