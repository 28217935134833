/* ServiceCard.css */
.transform.rotate-130 {
  transform: rotate(320deg);
}

.text-primary {
  color: #007bff; /* You can customize the primary text color */
}
.parent{
  overflow-x: hidden;
}



